import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import { LanguageProvider } from "./context/languageSwitcher";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    <LanguageProvider>
      <Header />
      <App />
      <Footer />
    </LanguageProvider>
  </Router>
);
