import "./App.css";
import AppRoutes from "./AppRoutes";
import { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <Suspense fallback={<div>Loading...</div>}>
        <AppRoutes />
      </Suspense>
    </HelmetProvider>
  );
}

export default App;
