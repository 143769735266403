import { gmailIcon, whatsappIcon } from "../../images";

export const footerData = [
  {
    icon: gmailIcon,
    content: "info@summitersclub.com",
  },
  {
    icon: whatsappIcon,
    content: "+995 555 22 03 49",
  },
  // {
  //   icon: googlePinIcon,
  //   content: "nepali saswauli adgili dedamiwaze",
  // },
];
