const home = "/";
const story = "/story";
const contact = "/contact";
const amaDablam = "/amaDablam";
const lobuchePage = "/lobuche";
const islandPeak = "/islandPeak";
const everestBaseCamp = "/everestBaseCamp";

const routes = {
  home,
  story,
  contact,
  amaDablam,
  lobuchePage,
  islandPeak,
  everestBaseCamp,
};

export default routes;
