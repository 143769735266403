import { useNavigate } from "react-router-dom";
import { footerData } from ".";
import { facebook, instagram, logo } from "../../images";
import { MaxContentWrapper } from "../max-content-wrapper/MaxContentWrapper";
import Typography from "../typography/Typography";
import ContactInfoIconBox from "./components/ContactInfoIconBox";
import {
  FooterWrapper,
  ContactInfoWrapper,
  ContactInfoIcons,
  SocialIcons,
  LogoImg,
} from "./style";

const Footer = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate("/");
  };
  return (
    <FooterWrapper>
      <MaxContentWrapper>
        <div>
          <LogoImg
            onClick={handleLogoClick}
            alt="logo"
            src={logo}
            loading="lazy"
          />
          <ContactInfoWrapper>
            <Typography fontSize="1.6rem">Contact information:</Typography>
            <ContactInfoIcons>
              {footerData.map((i) => {
                return (
                  <ContactInfoIconBox iconUrl={i.icon} content={i.content} />
                );
              })}
            </ContactInfoIcons>
          </ContactInfoWrapper>
        </div>
        <SocialIcons>
          <img alt="facebook" src={facebook} loading="lazy" />
          <img alt="instagram" src={instagram} loading="lazy" />
        </SocialIcons>
      </MaxContentWrapper>
    </FooterWrapper>
  );
};

export default Footer;
