import { useEffect, useRef, useState } from "react";
import routes from "../../constants/routes";
import {
  DropdownItem,
  DropdownMenu,
  NavItem,
  NavItemsWrapper,
  DisplayWrapper,
} from "./style";
import { useLocation } from "react-router-dom";

const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle scrolling to add background on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to top when the route changes
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  // Scroll to bottom for "Contact Us"
  const handleContactClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    window.scrollTo({
      top: document.documentElement.scrollHeight, // Scroll to bottom
      behavior: "smooth",
    });
  };

  // Toggle dropdown
  const handleDropdownToggle = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false); // Close dropdown
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  const navigationData = [
    { title: "Home |", route: routes.home },
    { title: "Expeditions" },
    { title: "|  Our Story & Missions |", route: routes.story },
    { title: "Contact Us" }, // No route needed
  ];

  const dropdownItems = [
    { title: "Amadablam", route: "/amaDablam" },
    { title: "Lobuche", route: "/Lobuche" },
    { title: "Everest Base Camp", route: "/everestBaseCamp" },
    { title: "Island Peak", route: "/islandPeak" },
  ];

  return (
    <DisplayWrapper>
      <NavItemsWrapper>
        {navigationData.map((item) => {
          if (item.title === "Contact Us") {
            return (
              <NavItem
                isScrolled={isScrolled}
                onClick={handleContactClick} // Scroll to bottom on click
                key={item.title}
                as="div" // Render as div since no route is needed
                style={{ cursor: "pointer" }}
                to={item.route}
              >
                {item.title}
              </NavItem>
            );
          }

          if (item.title === "Expeditions") {
            return (
              <div
                key={item.title}
                style={{ position: "relative" }}
                ref={dropdownRef}
              >
                <NavItem
                  isScrolled={isScrolled}
                  to={item.route}
                  onClick={handleDropdownToggle}
                >
                  {item.title} ▼
                </NavItem>
                {isDropdownOpen && (
                  <DropdownMenu>
                    {dropdownItems.map((dropdownItem) => (
                      <DropdownItem
                        key={dropdownItem.title}
                        href={dropdownItem.route}
                      >
                        {dropdownItem.title}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                )}
              </div>
            );
          }

          return (
            <div key={item.title}>
              <NavItem isScrolled={isScrolled} to={item.route}>
                {item.title}
              </NavItem>
            </div>
          );
        })}
      </NavItemsWrapper>
    </DisplayWrapper>
  );
};

export default Navigation;
