import { getImageUrl } from "../helpers/getImageUrl";

export const mountaintImage = getImageUrl("hmountaint.webp");
export const homePageBoxImageFirst = getImageUrl("homePageBoxImageFirst.webp");
export const starsImage = getImageUrl("starsImage.webp");
export const userOne = getImageUrl("userOne.webp");
export const userSecond = getImageUrl("userSecond.webp");
export const mountainWithSnow = getImageUrl("mountainWithSnow.webp");
export const homePageSecondBoxImage = getImageUrl(
  "homePageSecondBoxImage.webp"
);
export const gmailIcon = getImageUrl("gmailIcon.webp");
export const whatsappIcon = getImageUrl("whatsappIcon.webp");
export const googlePinIcon = getImageUrl("googlePinIcon.webp");
export const facebook = getImageUrl("Facebook.webp");
export const instagram = getImageUrl("Instagram.webp");
export const missionsPageHeadingPic = getImageUrl(
  "missionsPageHeadingPic.webp"
);
export const roundedImageFirst = getImageUrl("roundedImageFirst.webp");
export const MemberImage = getImageUrl("members.webp");
export const imageTextFirst = getImageUrl("imageTextFirst.webp");
export const imageTextSecond = getImageUrl("imageTextSecond.webp");
export const imageTextThird = getImageUrl("imageTextThird.webp");
export const amaDablamBackgroundImage = getImageUrl(
  "amadablamBackgroundImage.webp"
);
export const mission = getImageUrl("Mission.webp");
export const mountainIcon = getImageUrl("MountainIcon.webp");
export const dificultyIcon = getImageUrl("NoConnection.webp");
export const durationIcon = getImageUrl("Schedule.webp");
export const dailyActivityIcon = getImageUrl("Trekking.webp");
export const locationIcon = getImageUrl("WorldwideLocation.webp");
export const checkMark = getImageUrl("CheckMark.webp");
export const closeMark = getImageUrl("CloseMark.webp");
export const amaDablamImage1 = getImageUrl("amadablamImage1.webp");
export const amaDablamImage2 = getImageUrl("amadablamImage2.webp");
export const amaDablamImage3 = getImageUrl("amadablamImage3.webp");
export const amaDablamImage4 = getImageUrl("amadablamImage4.webp");
export const amaDablamImage5 = getImageUrl("amadablamImage5.webp");
export const amaDablamImage6 = getImageUrl("amadablamImage6.webp");
export const amaDablamImage7 = getImageUrl("amadablamImage7.webp");
export const amaDablamImage8 = getImageUrl("amadablamImage8.webp");
export const amaDablamImage9 = getImageUrl("amadablamImage9.webp");
export const nextIcon = getImageUrl("MoreThan.webp");
export const previousIcon = getImageUrl("LessThan.webp");
export const logo = getImageUrl("clubsLogo.webp");
export const imageBoxImageOne = getImageUrl("imageBoxImageOne.webp");
export const imageBoxImageSecond = getImageUrl("imageBoxImageTwo.webp");
export const imageBoxImageThree = getImageUrl("imageBoxImageThree.webp");
export const imageBoxImageFour = getImageUrl("imageBoxImageFour.webp");
export const storyBackgroundImage = getImageUrl("storyBackgroundImage.webp");
export const polygonFirst = getImageUrl("PolygonFirst.webp");
export const polygonSecond = getImageUrl("polygonSecond.webp");
export const memberOne = getImageUrl("memberOne.webp");
export const memberTwo = getImageUrl("memberTwo.webp");
export const lobucheBackgroundImage = getImageUrl(
  "lobucheBackgroundImage.webp"
);
export const lobucheImage1 = getImageUrl("lobucheImage1.webp");
export const lobucheImage2 = getImageUrl("lobucheImage2.webp");
export const lobucheImage3 = getImageUrl("lobucheImage3.webp");
export const lobucheImage4 = getImageUrl("lobucheImage4.webp");
export const lobucheAndEverest = getImageUrl("lobucheAndEverest.webp");
export const lobucheAndEverest2 = getImageUrl("lobucheAndEverest2.webp");
export const everestBaseCamp1 = getImageUrl("everestBaseCamp1.webp");
export const everestBaseCamp2 = getImageUrl("everestBaseCamp2.webp");
export const everestBaseCampBackgroundImage = getImageUrl(
  "everestBaseCampBackgroundImage.webp"
);
export const islandPeakBackgroundImage = getImageUrl(
  "islandPeakBackgroundImage.webp"
);
export const user1 = getImageUrl("user1.webp");
export const user2 = getImageUrl("user2.webp");
export const user3 = getImageUrl("user3.webp");
export const user4 = getImageUrl("user4.webp");
export const userGirl = getImageUrl("userGirl.webp");
export const amadablamImageForBackground = getImageUrl(
  "amadablamImageForBackground.webp"
);
export const backgroundVideo = getImageUrl("backgroundVideo.webm");
export const islandPeak1 = getImageUrl("island1.webp");
export const islandPeak2 = getImageUrl("island2.webp")
export const islandPeak3 = getImageUrl("island3.webp")
export const islandPeak4 = getImageUrl("island4.webp")
