import routes from "../constants/routes";
import {
  AmadablamPage,
  EverestBaseCampPage,
  HomePage,
  IslandPeakPage,
  LobuchePage,
  StoryMissionPage,
} from "../pages/lazt-loaders";

export const appRoutes = [
  {
    path: routes.home,
    Component: HomePage,
  },
  {
    path: routes.story,
    Component: StoryMissionPage,
  },
  {
    path: routes.amaDablam,
    Component: AmadablamPage,
  },
  {
    path: routes.lobuchePage,
    Component: LobuchePage,
  },
  {
    path: routes.islandPeak,
    Component: IslandPeakPage,
  },
  {
    path: routes.everestBaseCamp,
    Component: EverestBaseCampPage,
  },
];
